<template>
  <div>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Edit Category"
      ok-title="Edit"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          label="Label"
          label-for="name-input"
          invalid-feedback="Label is required"
        >
          <b-form-input
            id="name-input"
            v-model="toEdit.label"
            :state="nameState"
            required
          />
          <br />
          <b-button
            variant="primary"
            class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
            v-b-modal.modal-prevent-closing-icon
            block
          >
            <feather-icon :icon="toEdit.icon" class="mr-50" />
            <span>Icon</span>
          </b-button>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-prevent-closing-icon"
      ref="my-modal-icon"
      title="Icons"
      ok-title="icon"
      cancel-variant="outline-secondary"
    >
      <!-- icons -->
      <section id="feather-icons">
        <div class="icon-search-wrapper my-3 mx-auto">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="icons-search"
                v-model="seachQuery"
                placeholder="Search Icons..."
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div id="icons-container" class="d-flex flex-wrap">
          <b-card
            v-for="icon in filterIcons"
            :key="icon"
            v-b-tooltip.hover.top="icon"
            class="icon-card cursor-pointer text-center mb-2 mx-50"
            @click="copyIconName(icon)"
          >
            <div class="icon-wrapper">
              <feather-icon :icon="icon" size="24" />
            </div>
            <b-card-text class="icon-name text-truncate mb-0 mt-1">
              {{ icon }}
            </b-card-text>
          </b-card>
        </div>
      </section>
      <!-- icons -->
    </b-modal>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Ajouter Catégories</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="5" class="mb-md-0 mb-2">
            <b-form-input
              v-model="category.label"
              placeholder="Label"
              type="text"
              required
            />
            <p
              style="color: #dc143c; font-size: 11px"
              v-if="show === true && category.label === ''"
            >
              <strong> Label is required </strong>
            </p>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <b-button
              variant="primary"
              class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              v-b-modal.modal-prevent-closing-icon
              block
            >
              <feather-icon :icon="category.icon" class="mr-50" />
              <span>Icon</span>
            </b-button>
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-button variant="primary" block @click="_addCategory()">
              Ajouter
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card-actions action-collapse title="Liste des catégories">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <!--<label class="mr-1">Recherche</label>-->
            <b-form-input
              placeholder="Recherche"
              type="text"
              class="d-inline-block mr-1"
              @input="advanceSearch"
            />
          </div>
        </b-form-group>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-cart"
              @click="deletedSelected()"
            >
              <feather-icon icon="TrashIcon" class="" />
            </b-button>
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        ref="table-category"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
          //  mode: 'records'
        }"
        theme="my-theme"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'Label'" class="text-nowrap">
            <span>
              <feather-icon :icon="props.row.icon" /> {{ props.row.label }}
            </span>
          </span>
          <span v-else-if="props.column.field === 'subCategories'">
            <app-collapse>
              <app-collapse-item
                :title="props.row.subCategories.length + ' Sous Catégories'"
              >
                <div v-for="sub in props.row.subCategories" :key="sub._id">
                  {{ sub.label }}
                  <hr />
                </div>
              </app-collapse-item>
            </app-collapse>
          </span>
          <span v-else-if="props.column.label === 'Action'" class="text-nowrap">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  id="toggle-btn"
                  v-b-modal.modal-prevent-closing
                  @click="_editCategory(props.row)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="_deleteCategory(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Afficher 1 à </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> sur {{ props.total }} résultats </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import feather from "../feather/feather";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import CategoryMixin from "@/mixins/category.mixin";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BAlert,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  VBTooltip,
  BPagination,
  BFormSelect,
  BForm,
  BTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useClipboard } from "@vueuse/core";
import { useToast } from "vue-toastification/composition";
export default {
  mixins: [CategoryMixin],

  components: {
    BPagination,
    BFormSelect,
    BForm,
    BTooltip,
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    VBTooltip,
    BCardActions,
    BFormInput,
    ToastificationContent,
    feather,
    BFormGroup,
    BFormInvalidFeedback,
    BListGroup,
    BListGroupItem,
    BAlert,
    BModal,
    VBModal,
    BDropdown,
    BDropdownItem,
    BButton,

    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      pageLength: 5,
      searchTerm: "",
      seachQuery: "",
      icons: [
        "ActivityIcon",
        "AlertCircleIcon",
        "AlertTriangleIcon",
        "AlignJustifyIcon",
        "AnchorIcon",
        "ApertureIcon",
        "ArchiveIcon",
        "AtSignIcon",
        "AwardIcon",
        "BarChart2Icon",
        "BarChartIcon",
        "BatteryChargingIcon",
        "BatteryIcon",
        "BellIcon",
        "BluetoothIcon",
        "BookOpenIcon",
        "BookIcon",
        "BookmarkIcon",
        "BoxIcon",
        "BriefcaseIcon",
        "CalendarIcon",
        "CameraIcon",
        "CircleIcon",
        "ClipboardIcon",
        "ClockIcon",
        "CloudDrizzleIcon",
        "CloudLightningIcon",
        "CloudRainIcon",
        "CloudSnowIcon",
        "CloudIcon",
        "CoffeeIcon",
        "CopyIcon",
        "CpuIcon",
        "CreditCardIcon",
        "CropIcon",
        "DatabaseIcon",
        "DiscIcon",
        "DollarSignIcon",
        "DropletIcon",
        "Edit2Icon",
        "Edit3Icon",
        "EditIcon",
        "EyeIcon",
        "FacebookIcon",
        "FeatherIcon",
        "FileTextIcon",
        "FileIcon",
        "FilmIcon",
        "FilterIcon",
        "FlagIcon",
        "FolderIcon",
        "GiftIcon",
        "GithubIcon",
        "GitlabIcon",
        "GlobeIcon",
        "GridIcon",
        "HeadphonesIcon",
        "HeartIcon",
        "HelpCircleIcon",
        "HomeIcon",
        "ImageIcon",
        "InboxIcon",
        "InfoIcon",
        "InstagramIcon",
        "LayersIcon",
        "LinkIcon",
        "ListIcon",
        "LoaderIcon",
        "LockIcon",
        "MapPinIcon",
        "MapIcon",
        "MenuIcon",
        "MessageCircleIcon",
        "MessageSquareIcon",
        "MicIcon",
        "MonitorIcon",
        "MoonIcon",
        "MusicIcon",
        "NavigationIcon",
        "PenToolIcon",
        "PhoneCallIcon",
        "PhoneIcon",
        "PieChartIcon",
        "PlayIcon",
        "PowerIcon",
        "PrinterIcon",
        "RadioIcon",
        "RefreshCcwIcon",
        "RefreshCwIcon",
        "RepeatIcon",
        "RssIcon",
        "SaveIcon",
        "ScissorsIcon",
        "SearchIcon",
        "SendIcon",
        "ServerIcon",
        "SettingsIcon",
        "Share2Icon",
        "ShareIcon",
        "ShieldIcon",
        "ShoppingBagIcon",
        "ShoppingCartIcon",
        "SlidersIcon",
        "SmartphoneIcon",
        "SpeakerIcon",
        "SquareIcon",
        "StarIcon",
        "SunIcon",
        "TabletIcon",
        "TagIcon",
        "ThermometerIcon",
        "ToolIcon",
        "Trash2Icon",
        "TrashIcon",
        "TriangleIcon",
        "TruckIcon",
        "TvIcon",
        "TwitterIcon",
        "UmbrellaIcon",
        "UnlockIcon",
        "UserIcon",
        "UsersIcon",
        "VideoIcon",
        "VoicemailIcon",
        "Volume1Icon",
        "Volume2Icon",
        "VolumeIcon",
        "WatchIcon",
        "WifiIcon",
        "ZapIcon",
        "KeyIcon",
      ],
      show: false,
      name: "",
      nameState: null,
      submittedNames: [],
      label: "",
      icon: "",
      toEdit: {},
      category: {
        label: "",
        icon: "",
      },
      columns: [
        {
          label: "Label",
          field: "label",
        },
        {
          label: "sous Catégories",
          field: "subCategories",
        },
        {
          label: "Action",
          field: "label",
        },
      ],
      rows: [],
    };
  },

  methods: {
    deletedSelected() {
      console.log("hi selected ", this.$refs["table-category"].selectedRows);
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          (async () => {
            this.$refs["table-category"].selectedRows.forEach(async (c) => {
              await this.$store.dispatch("DeleteCategory", c).then((data) => {
                if (data.data != "") {
                  this.rows = [];
                  this.rows = this.categories;
                }
              });
            });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Successfylly Deleted",
                icon: "TrashIcon",
                variant: "success",
              },
            });
          })();
          /*this.$store.dispatch("DeleteCategory", category).then((data) => {
            if (data.data != "") {
              this.rows = [];

              this.rows = this.categories;
            }
          });*/
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annuler la suppression ",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    // search category
    advanceSearch(val) {
      this.searchTerm = val;
    },
    copyIconName(iconName) {
      const toast = useToast();
      const { copy } = useClipboard();
      copy(iconName);
      console.log("iconName", iconName);
      var iconMobile = "home-outline";
      switch (iconName) {
        case "HomeIcon":
          iconMobile = "home-outline";
          break;
        case "KeyIcon":
          iconMobile = "key-outline";
          break;
        case "ActivityIcon":
          iconMobile = "pulse-outline";
          break;
        case "AlertCircleIcon":
          iconMobile = "alert-circle-outline";
          break;
        case "AlertTriangleIcon":
          iconMobile = "warning-outline";
          break;
        case "AlignJustifyIcon":
          iconMobile = "menu-outline";
          break;
        case "AnchorIcon":
          iconMobile = "boat-outline";
          break;
        case "ApertureIcon":
          iconMobile = "aperture-outline";
          break;
        case "ArchiveIcon":
          iconMobile = "Home-outline";
          break;
        case "AtSignIcon":
          iconMobile = "at-outline";
          break;
        case "AwardIcon":
          iconMobile = "trophy-outline";
          break;
        case "BarChart2Icon":
          iconMobile = "bar-chart-outline";
          break;
        case "BarChartIcon":
          iconMobile = "bar-chart-outline";
          break;
        case "BatteryChargingIcon":
          iconMobile = "battery-charging-outline";
          break;
        case "BatteryIcon":
          iconMobile = "battery-dead-outline";
          break;
        case "BellIcon":
          iconMobile = "notifications-outline";
          break;
        case "BluetoothIcon":
          iconMobile = "bluetooth-outline";
          break;
        case "BookOpenIcon":
          iconMobile = "book-outline";
          break;
        case "BookIcon":
          iconMobile = "book-outline";
          break;
        case "BookmarkIcon":
          iconMobile = "bookmark-outline";
          break;
        case "BoxIcon":
          iconMobile = "cube-outline";
          break;
        case "BriefcaseIcon":
          iconMobile = "briefcase-outline";
          break;
        case "CalendarIcon":
          iconMobile = "calendar-outline";
          break;
        case "CameraIcon":
          iconMobile = "camera-outline";
          break;
        case "CircleIcon":
          iconMobile = "ellipse-outline";
          break;
        case "ClipboardIcon":
          iconMobile = "clipboard-outline";
          break;
        case "ClockIcon":
          iconMobile = "time-outline";
          break;
        case "CloudDrizzleIcon":
          iconMobile = "thunderstorm-outline";
          break;
        case "CloudLightningIcon":
          iconMobile = "thunderstorm-outline";
          break;
        case "CloudRainIcon":
          iconMobile = "rainy-outline";
          break;
        case "CloudSnowIcon":
          iconMobile = "rainy-outline";
          break;
        case "CloudIcon":
          iconMobile = "cloud-outline";
          break;
        case "CoffeeIcon":
          iconMobile = "cafe-outline";
          break;
        case "CopyIcon":
          iconMobile = "copy-outline";
          break;
        case "CpuIcon":
          iconMobile = "hardware-chip-outline";
          break;
        case "CreditCardIcon":
          iconMobile = "card-outline";
          break;
        case "CropIcon":
          iconMobile = "crop-outline";
          break;
        case "DatabaseIcon":
          iconMobile = "server-outline";
          break;
        case "DiscIcon":
          iconMobile = "disc-outline";
          break;
        case "DollarSignIcon":
          iconMobile = "cash-outline";
          break;
        case "DropletIcon":
          iconMobile = "water-outline";
          break;
        case "Edit2Icon":
          iconMobile = "pencil-outline";
          break;
        case "Edit3Icon":
          iconMobile = "pencil-outline";
          break;
        case "EditIcon":
          iconMobile = "pencil-outline";
          break;
        case "EyeIcon":
          iconMobile = "eye-outline";
          break;
        case "FacebookIcon":
          iconMobile = "logo-facebook";
          break;
        case "FeatherIcon":
          iconMobile = "brush-outline";
          break;
        case "FileTextIcon":
          iconMobile = "document-text-outline";
          break;
        case "FileIcon":
          iconMobile = "document-outline";
          break;
        case "FilmIcon":
          iconMobile = "film-outline";
          break;
        case "FilterIcon":
          iconMobile = "funnel-outline";
          break;
        case "FlagIcon":
          iconMobile = "flag-outline";
          break;
        case "FolderIcon":
          iconMobile = "folder-outline";
          break;
        case "GiftIcon":
          iconMobile = "gift-outline";
          break;
        case "GithubIcon":
          iconMobile = "logo-github";
          break;
        case "GitlabIcon":
          iconMobile = "logo-gitlab";
          break;
        case "GlobeIcon":
          iconMobile = "globe-outline";
          break;
        case "GridIcon":
          iconMobile = "grid-outline";
          break;
        case "HeadphonesIcon":
          iconMobile = "headset-outline";
          break;
        case "HeartIcon":
          iconMobile = "heart-outline";
          break;
        case "HelpCircleIcon":
          iconMobile = "help-circle-outline";
          break;
        case "ImageIcon":
          iconMobile = "image-outline";
          break;
        case "InboxIcon":
          iconMobile = "file-tray-outline";
          break;
        case "InfoIcon":
          iconMobile = "information-circle-outline";
          break;
        case "InstagramIcon":
          iconMobile = "logo-instagram-outline";
          break;
        case "LayersIcon":
          iconMobile = "layers-outline";
          break;
        case "LinkIcon":
          iconMobile = "link-outline";
          break;
        case "ListIcon":
          iconMobile = "list-outline";
          break;
        case "LoaderIcon":
          iconMobile = "reload-outline";
          break;
        case "LockIcon":
          iconMobile = "time-outline";
          break;
        case "MapPinIcon":
          iconMobile = "locate-outline";
          break;
        case "MapIcon":
          iconMobile = "map-outline";
          break;
        case "MenuIcon":
          iconMobile = "menu-outline";
          break;
        case "MessageCircleIcon":
          iconMobile = "chatbubble-outline";
          break;
        case "MessageSquareIcon":
          iconMobile = "chatbubble-outline";
          break;
        case "MicIcon":
          iconMobile = "mic-outline";
          break;
        case "MonitorIcon":
          iconMobile = "tv-outline";
          break;
        case "MoonIcon":
          iconMobile = "moon-outline";
          break;
        case "MusicIcon":
          iconMobile = "musical-notes-outline";
          break;
        case "NavigationIcon":
          iconMobile = "navigate-outline";
          break;
        case "PenToolIcon":
          iconMobile = "pencil-outline";
          break;
        case "PhoneCallIcon":
          iconMobile = "call-outline";
          break;
        case "PhoneIcon":
          iconMobile = "phone-portrait-outline";
          break;
        case "PieChartIcon":
          iconMobile = "pie-chart-outline";
          break;
        case "PlayIcon":
          iconMobile = "play-outline";
          break;
        case "PowerIcon":
          iconMobile = "power-outline";
          break;
        case "PrinterIcon":
          iconMobile = "print-outline";
          break;
        case "RadioIcon":
          iconMobile = "radio-outline";
          break;
        case "RefreshCcwIcon":
          iconMobile = "refresh-outline";
          break;
        case "RefreshCwIcon":
          iconMobile = "refresh-outline";
          break;
        case "RepeatIcon":
          iconMobile = "repeat-outline";
          break;
        case "RssIcon":
          iconMobile = "logo-rss";
          break;
        case "SaveIcon":
          iconMobile = "save-outline";
          break;
        case "ScissorsIcon":
          iconMobile = "cut-outline";
          break;
        case "SearchIcon":
          iconMobile = "search-outline";
          break;
        case "SendIcon":
          iconMobile = "send-outline";
          break;
        case "ServerIcon":
          iconMobile = "server-outline";
          break;
        case "SettingsIcon":
          iconMobile = "settings-outline";
          break;
        case "Share2Icon":
          iconMobile = "share-social-outline";
          break;
        case "ShareIcon":
          iconMobile = "share-outline";
          break;
        case "ShieldIcon":
          iconMobile = "shield-outline";
          break;
        case "ShoppingBagIcon":
          iconMobile = "bag-handle-outline";
          break;
        case "ShoppingCartIcon":
          iconMobile = "cart-outline";
          break;
        case "SlidersIcon":
          iconMobile = "options-outline";
          break;
        case "SmartphoneIcon":
          iconMobile = "phone-portrait-outline";
          break;
        case "SpeakerIcon":
          iconMobile = "volume-high-outline";
          break;
        case "SquareIcon":
          iconMobile = "square-outline";
          break;
        case "StarIcon":
          iconMobile = "star-outline";
          break;
        case "SunIcon":
          iconMobile = "sunny-outline";
          break;
        case "TabletIcon":
          iconMobile = "tablet-portrait-outline";
          break;
        case "TagIcon":
          iconMobile = "pricetag-outline";
          break;
        case "ThermometerIcon":
          iconMobile = "thermometer-outline";
          break;
        case "ToolIcon":
          iconMobile = "hammer-outline";
          break;
        case "Trash2Icon":
          iconMobile = "trash-outline";
          break;
        case "TrashIcon":
          iconMobile = "trash-outline";
          break;
        case "TriangleIcon":
          iconMobile = "triangle-outline";
          break;
        case "TruckIcon":
          iconMobile = "bus-outline";
          break;
        case "TvIcon":
          iconMobile = "tv-outline";
          break;
        case "TwitterIcon":
          iconMobile = "logo-twitter";
          break;
        case "UmbrellaIcon":
          iconMobile = "umbrella-outline";
          break;
        case "UnlockIcon":
          iconMobile = "lock-open-outline";
          break;
        case "UserIcon":
          iconMobile = "person-outline";
          break;
        case "UsersIcon":
          iconMobile = "people-outline";
          break;
        case "VideoIcon":
          iconMobile = "videocam-outline";
          break;
        case "VoicemailIcon":
          iconMobile = "recording-outline";
          break;
        case "Volume1Icon":
          iconMobile = "volume-low-outline";
          break;
        case "Volume2Icon":
          iconMobile = "volume-medium-outline";
          break;
        case "VolumeIcon":
          iconMobile = "volume-off-outline";
          break;
        case "WatchIcon":
          iconMobile = "watch-outline";
          break;
        case "WifiIcon":
          iconMobile = "wifi-outline";
          break;
        case "ZapIcon":
          iconMobile = "flash-outline";
          break;
        default:
          iconMobile = "Home-outline";
          break;

          console.log("iconName", iconMobile);
          this.category.icon = iconMobile;
          this.toEdit.icon = iconMobile;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "You choose " + iconName + " icon",
              icon: iconName,
              variant: "success",
            },
          });
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store.dispatch("UpdateCategory", this.toEdit).then((data) => {
        if (data._id !== undefined) {
          this.toEdit = {};
          this.rows = [];
          let self = this;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Category Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          setTimeout(function () {
            self.rows = self.categories;
          }, 1000);
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Category failed",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },
    _addCategory() {
      this.category.subCategories = [];
      console.log("test cat ", this.category);
      if (this.category.label === "" || this.category.label === undefined) {
        this.show = true;
      } else {
        this.$store.dispatch("AddCategory", this.category).then((data) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Add Category Success",
              icon: "PlusIcon",
              variant: "success",
            },
          });
          this.category.label = "";
          this.category.icon = "";
          this.show = false;
        });
      }
    },

    _editCategory(category) {
      this.toEdit = Object.assign({}, category);
    },
    _deleteCategory(category) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: "Vous ne pouvez pas annuler votre action",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Delete it!",
        cancelButtonText: "No, Keep it!",
        confirmButtonColor: "red",
        cancelButtonColor: "green",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("DeleteCategory", category).then((data) => {
            if (data.data != "") {
              this.rows = [];

              this.rows = this.categories;
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annuler la suppression ",
              icon: "TrashIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
  mounted() {
    this.rows = this.categories;
    console.log("rows ", this.rows);
  },

  watch: {
    categories: function () {
      this.rows = this.categories;
      console.log("rows 2", this.rows);
    },
    category: function () {
      if (this.category.label !== "") {
        this.show = false;
      }
    },
  },

  computed: {
    filterIcons() {
      const seachQueryIcon = this.seachQuery.toLowerCase();
      return this.icons.filter((item) =>
        item.toLowerCase().includes(seachQueryIcon)
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/ui-feather.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>